import React from 'react'

import {
  SectionTitle
} from '../components/UI'

const NotFoundPage = () => (
  <section className='section section-404'>
    <SectionTitle
      title='Not Found'
      subtitle="The page you are looking for doesn't exist"
    />
  </section>
)

export default NotFoundPage
